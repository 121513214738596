
import {
    defineComponent, Prop, PropType, reactive
} from 'vue';
import { RecordType } from '@/views/pm/home/all.type';

export default defineComponent({
    emits: ['click'],
    props: {
        recordData: {
            type: Object as PropType<RecordType>,
            required: true
        }
    }
});
